import type { Route } from './+types/home'

export function loader({ context, request }: Route.LoaderArgs) {
  const headers = Object.fromEntries(request.headers.entries())

  return { message: context.env.VERSION, tenantId: context.tenantId }
}

export default function Page({ loaderData }: Route.ComponentProps) {
  return (
    <div className="p-4">
      <b>{loaderData.tenantId}</b> beta
    </div>
  )
}
